import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { element } from 'prop-types';


// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
//const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
//const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));


// sample page routing
const Categoria = Loadable(lazy(() => import('views/categoria')));
const Todos = Loadable(lazy(() => import('views/usuarios/todos')));
const Usuarios = Loadable(lazy(() => import('views/usuarios')));
const ProfileEdit = Loadable(lazy(() => import('views/usuarios/perfilUpdate')));
const Profesionales = Loadable(lazy(() => import('views/usuarios/profesionales')));
const Administradores = Loadable(lazy(() => import('views/usuarios/administradores')));
const Eliminados = Loadable(lazy(() => import('views/usuarios/eliminados')));
const ReportePorProfesion = Loadable(lazy(() => import('views/reportes/porProfesion')));
const DesgloseCategorias = Loadable(lazy(() => import('views/reportes/desgloseCategorias')));
const Servicios = Loadable(lazy(() => import('views/servicios')));
const Logs= Loadable(lazy(() => import('views/logs')));
const Tareas= Loadable(lazy(() => import('views/logs/tareas')));

const Reportados = Loadable(lazy(() => import('views/reportados')));
const Publicaciones = Loadable(lazy(() => import('views/publicaciones')));
const NotFound = Loadable(lazy(() => import('views/errors/NotFound')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard/default',
            element: <DashboardDefault />
        },

        {
            path: 'utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: 'icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: 'icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: 'categoria',
            element: <Categoria />
        },
        {
          path: 'costumers',
          element: <Todos />
        },
        {
          path: 'usuarios',
          element: <Usuarios />
        },
        {
          path: 'usuario/editProfile',
          element: <ProfileEdit/>
        },
        {
          path: 'profesionales',
          element: <Profesionales />
        },
        {
          path: 'administradores',
          element: <Administradores />
        },
        {
          path: 'servicios',
          element: <Servicios />
        },
        {
          path: 'eliminados',
          element: <Eliminados />
        },
        {
            path: 'pages/nuevo-usuario',
            element: <AuthRegister3 />
        },
        {
          path: 'utils/totalesCategoria',
          element: <ReportePorProfesion />
        },
        {
          path: 'utils/DesgloseCategorias/:id',
          element: <DesgloseCategorias />
        },
        {
          path: 'reportados',
          element: <Reportados />
        },
        {
          path: 'publicaciones',
          element: <Publicaciones />
        },
        {
          path: 'logs',
          element: <Logs />
        },
        {
          path: 'tareas',
          element: <Tareas />
        },
        {
          path: '*',
          element: <NotFound />
        }
    ]
};

export default MainRoutes;

/*
{
    path: 'utils/util-typography',
    element: <UtilsTypography />
},
{
    path: 'utils/util-color',
    element: <UtilsColor />
},
*/
