// assets
import { IconAdjustmentsHorizontal, IconUsers,IconShieldLock } from '@tabler/icons';

// constant
const icons = {
    IconAdjustmentsHorizontal,
    IconUsers, IconShieldLock
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'mantenimiento',
    title: 'Administración',
    caption: 'Gestión de AFI',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Configuraciones',
            type: 'collapse',
            icon: icons.IconAdjustmentsHorizontal,
            children: [
                {
                    id: 'categoria',
                    title: 'Categoria',
                    type: 'item',
                    url: '/categoria',
                    icon: icons.IconBrandChrome,
                    breadcrumbs: false
                },
                {
                    id: 'servicios',
                    title: 'Asociados Afi',
                    type: 'item',
                    url: '/servicios',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'users',
            title: 'Gestión de Costumer',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'register3',
                    title: 'Crear Administrador',
                    type: 'item',
                    url: '/pages/nuevo-usuario',
                    breadcrumbs: false
                },
                {
                    id: 'costumers',
                    title: 'Todos los Costumers',
                    type: 'item',
                    url: '/costumers',
                },
                {
                    id: 'usuarios',
                    title: 'Usuarios',
                    type: 'item',
                    url: '/usuarios',
                },
                {
                    id: 'profesionales',
                    title: 'Profesionales',
                    type: 'item',
                    url: '/profesionales',
                    breadcrumbs: false
                },
                {
                    id: 'administradores',
                    title: 'Administradores',
                    type: 'item',
                    url: '/administradores',
                    breadcrumbs: false
                },
                {
                    id: 'eliminados',
                    title: 'Costumer Eliminados',
                    type: 'item',
                    url: '/eliminados',
                    icon: icons.IconBrandChrome,
                    breadcrumbs: false
                },

            ]
        },
        {
            id: 'registros',
            title: 'Logs / registros',
            type: 'item',
            url: '/logs',
            icon: icons.IconShieldLock,
            //external: true,
            //target: true
        },
    ],
    
};

export default pages;
